.Penalty .penalties {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Penalty .row [class^=col-]{
		text-align: center;
	}
  .Penalty table thead tr th:nth-child(3),
	.Penalty table thead tr th:nth-child(4),
  .Penalty table thead tr th:nth-child(5),
  .Penalty table thead tr th:nth-child(6),
  .Penalty table thead tr th:nth-child(7),
  .Penalty table thead tr th:nth-child(8),
	.Penalty table tbody tr td:nth-child(3),
  .Penalty table tbody tr td:nth-child(4),
  .Penalty table tbody tr td:nth-child(5),
  .Penalty table tbody tr td:nth-child(6),
  .Penalty table tbody tr td:nth-child(7),
  .Penalty table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Penalty .row [class^=col-]{
		text-align: center;
	}
  .Penalty table thead tr th:nth-child(7),
  .Penalty table thead tr th:nth-child(8),
	.Penalty table tbody tr td:nth-child(7),
  .Penalty table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.received {
  background-color: #ffb3b3;
}

.departed {
  background-color: #ccffcc;
}

.radio {
	margin-left: 15px;
}

.psmall-center {
  font-size: 12px;
  text-align: center;
}

.penalty {
  background-color: #ffb3b3;
}

.warning {
  background-color: #f2711c;
}

.nopenalty {
  background-color: #ccffcc;
}

.Penalty .slots {
  margin-left: 20px;
}

.Penalty .date-picker {
  display: block;
  text-align: center;
  width: 132px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
}
