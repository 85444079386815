.AccessControl {
  padding: 7px;
}

.AccessControl form {
  padding-bottom: 15px;
}

.AccessControl form textarea {
  height: 300px;
  font-size: 24px;
}
.AccessControl .accessBtn {
  display: inline-block;
  padding-left: 15px;
}
.AccessControl .detail-buttons .accessBtn {
  padding-left: 0px;
}
.AccessControl .detail-buttons .accessBtn .glyphicon {
  margin-right: 5px;
}
.AccessControl .btn-group > button {
  float: right;
  margin-left: 10px;
}
.AccessControl .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.AccessControl .headerBtn > button {
  float: none;
  margin-left: 10px;
}
.AccessControl .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.AccessControl > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.AccessControl .pheader {
  padding-right: 15px;
}
