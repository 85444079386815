.User {
  padding: 7px;
}

.User form {
  padding-bottom: 15px;
}

.User form textarea {
  height: 300px;
  font-size: 24px;
}
.User .userBtn {
  display: inline-block;
  padding-left: 15px;
}
.User .detail-buttons .userBtn {
  padding-left: 0px;
}
.User .detail-buttons .userBtn .glyphicon {
  margin-right: 5px;
}
.User .btn-group > button {
  float: right;
  margin-left: 10px;
}
.User .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.User > .alert {
  margin: 15px;
  margin-bottom: 0;
}
