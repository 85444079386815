.Booking {
  padding: 7px;
}

.Booking form {
  padding-bottom: 15px;
}

.Booking form textarea {
  height: 300px;
  font-size: 24px;
}
.Booking .bookingBtn {
  display: inline-block;
  padding-left: 15px;
}
.Booking .detail-buttons .bookingBtn {
  padding-left: 0px;
}
.Booking .detail-buttons .bookingBtn .glyphicon {
  margin-right: 5px;
}
.Booking .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Booking .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Booking .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.Booking > .alert {
  margin: 15px;
  margin-bottom: 0;
}

@media (max-width: 767px)  {
  .modal-dialog .modal-body iframe {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 850px;
  }
}

.Booking .pheader {
  padding-right: 15px;
}

.deliveries .received-icon {
  color: red;
}

.deliveries .delivered-icon {
  color: green;
}
