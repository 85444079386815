.resident_picture {
  float: left;
  width: 200px;
  z-index: 1000;
}

.resident_picture_full {
  z-index: 1000;
  width: 1000px;
  color: red;
}
