.Login .forgotPasswordText {
  display: inline-block;
  margin-top: 15px;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 680px;
  }
}
.modal .terms {
  height: 400px;
  overflow: auto;
  padding-right: 10px;
}
.modal .terms h1 {
  text-align: center;
}
.modal .terms p, ul li, ol li {
  text-align: justify;
}
