.Penalty {
  padding: 7px;
}

.Penalty form {
  padding-bottom: 15px;
}

.Penalty form textarea {
  height: 300px;
  font-size: 24px;
}
.Penalty .penaltyBtn {
  display: inline-block;
  padding-left: 15px;
}
.Penalty .detail-buttons .penaltyBtn {
  padding-left: 0px;
}
.Penalty .detail-buttons .penaltyBtn .glyphicon {
  margin-right: 5px;
}
.Penalty .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Penalty .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Penalty .headerBtn > button {
  float: none;
  margin-left: 10px;
}
.Penalty .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Penalty > .alert {
  margin: 15px;
  margin-bottom: 0;
}
.Penalty .pheader {
  padding-right: 15px;
}
