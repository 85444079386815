
.datetime-picker {
  width: 160px;
  text-align: center;
}
.react-datepicker-popper {
  z-index: 9999;
}

.radioPenaltyType {
  text-align: left !important;
}
