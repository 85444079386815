.Unit .units {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Unit .row [class^=col-]{
		text-align: center;
	}
  .Unit table thead tr th:nth-child(3),
	.Unit table thead tr th:nth-child(4),
  .Unit table thead tr th:nth-child(5),
  .Unit table thead tr th:nth-child(6),
  .Unit table thead tr th:nth-child(7),
  .Unit table thead tr th:nth-child(8),
	.Unit table tbody tr td:nth-child(3),
  .Unit table tbody tr td:nth-child(4),
  .Unit table tbody tr td:nth-child(5),
  .Unit table tbody tr td:nth-child(6),
  .Unit table tbody tr td:nth-child(7),
  .Unit table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Unit .row [class^=col-]{
		text-align: center;
	}
  .Unit table thead tr th:nth-child(7),
  .Unit table thead tr th:nth-child(8),
	.Unit table tbody tr td:nth-child(7),
  .Unit table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.unitStatusWarning {
  background-color: #ffb3b3;
}

.unitStatusActive {
  background-color: #ccffcc;
}
