.sign-out {
  text-align: right;
  background: #f5f5f5 !important;
}

.concierge {
  background: #f5f5f5 !important;
}

.h-basic-single {
  width: 200px;
}

.logo_image {
  width: 50px !important;
  height: 50px !important;
}

.logo_item {
  padding: 5px !important;
}

.h-menu {
  /* background: linear-gradient(180deg, #1f88be, transparent) !important; */
}
