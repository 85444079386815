.Visitor .visitors {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Visitor .row [class^=col-]{
		text-align: center;
	}
  .Visitor table thead tr th:nth-child(3),
	.Visitor table thead tr th:nth-child(4),
  .Visitor table thead tr th:nth-child(5),
  .Visitor table thead tr th:nth-child(6),
  .Visitor table thead tr th:nth-child(7),
  .Visitor table thead tr th:nth-child(8),
	.Visitor table tbody tr td:nth-child(3),
  .Visitor table tbody tr td:nth-child(4),
  .Visitor table tbody tr td:nth-child(5),
  .Visitor table tbody tr td:nth-child(6),
  .Visitor table tbody tr td:nth-child(7),
  .Visitor table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Visitor .row [class^=col-]{
		text-align: center;
	}
  .Visitor table thead tr th:nth-child(7),
  .Visitor table thead tr th:nth-child(8),
	.Visitor table tbody tr td:nth-child(7),
  .Visitor table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.received {
  background-color: #ffb3b3;
}

.departed {
  background-color: #ccffcc;
}

.radio {
	margin-left: 15px;
}

.psmall-center {
  font-size: 12px;
  text-align: center;
}

.penalty {
  background-color: #ffb3b3;
}

.nopenalty {
  background-color: #ccffcc;
}

.Visitor .slots {
  margin-left: 20px;
}

.Visitor .date-picker {
  display: block;
  text-align: center;
  width: 132px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
}

.visitor_picture_modal {
  display: block !important;
  margin: auto !important;
}

.visitor-exceeded-label {
  background-color: green;
  animation-name: color_change_visitor;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@-webkit-keyframes color_change_visitor {
	from { background-color: red; }
	to { background-color: gray; }
}
@-moz-keyframes color_change_visitor {
  from { background-color: red; }
	to { background-color: gray; }
}
@-ms-keyframes color_change_visitor {
  from { background-color: red; }
	to { background-color: gray; }
}
@-o-keyframes color_change_visitor {
  from { background-color: red; }
	to { background-color: gray; }
}
@keyframes color_change_visitor {
  from { background-color: red; }
	to { background-color: gray; }
}
