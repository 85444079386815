.Unit {
  padding: 7px;
}

.Unit form {
  padding-bottom: 15px;
}

.Unit form textarea {
  height: 300px;
  font-size: 24px;
}
.Unit .unitBtn {
  display: inline-block;
  padding-left: 15px;
}
.Unit .detail-buttons .unitBtn {
  padding-left: 0px;
}
.Unit .detail-buttons .unitBtn .glyphicon {
  margin-right: 5px;
}
.Unit .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Unit .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Unit .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.Unit > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.Unit .pheader {
  padding-right: 15px;
}
