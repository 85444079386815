.Pet {
  padding: 7px;
}

.Pet form {
  padding-bottom: 15px;
}

.Pet form textarea {
  height: 300px;
  font-size: 24px;
}
.Pet .petBtn {
  display: inline-block;
  padding-left: 15px;
}
.Pet .detail-buttons .petBtn {
  padding-left: 0px;
}
.Pet .detail-buttons .petBtn .glyphicon {
  margin-right: 5px;
}
.Pet .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Pet .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Pet .headerBtn > button {
  float: none;
  margin-left: 50px;
}
.Pet .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.Pet > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.radio {
	margin-left: 15px;
}

.Pet .pheader {
  padding-right: 15px;
}
