.Delivery {
  padding: 7px;
}

.Delivery form {
  padding-bottom: 15px;
}

.Delivery form textarea {
  height: 300px;
  font-size: 24px;
}
.Delivery .deliveryBtn {
  display: inline-block;
  padding-left: 15px;
}
.Delivery .detail-buttons .deliveryBtn {
  padding-left: 0px;
}
.Delivery .detail-buttons .deliveryBtn .glyphicon {
  margin-right: 5px;
}
.Delivery .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Delivery .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Delivery .headerBtn > button {
  float: none;
  margin-left: 50px;
}
.Delivery .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.Delivery > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.Delivery .date-picker {
  display: block;
  text-align: center;
  width: 132px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
}

@media (max-width: 767px)  {
  .modal-dialog .modal-body iframe {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 850px;
  }
}

.Delivery .pheader {
  padding-right: 15px;
}

.deliveries .received-icon {
  color: red;
}

.deliveries .delivered-icon {
  color: green;
}
