.Pet .pets {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Pet .row [class^=col-]{
		text-align: center;
	}
  .Pet table thead tr th:nth-child(3),
	.Pet table thead tr th:nth-child(4),
  .Pet table thead tr th:nth-child(5),
  .Pet table thead tr th:nth-child(6),
  .Pet table thead tr th:nth-child(7),
  .Pet table thead tr th:nth-child(8),
	.Pet table tbody tr td:nth-child(3),
  .Pet table tbody tr td:nth-child(4),
  .Pet table tbody tr td:nth-child(5),
  .Pet table tbody tr td:nth-child(6),
  .Pet table tbody tr td:nth-child(7),
  .Pet table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Pet .row [class^=col-]{
		text-align: center;
	}
  .Pet table thead tr th:nth-child(7),
  .Pet table thead tr th:nth-child(8),
	.Pet table tbody tr td:nth-child(7),
  .Pet table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.email-active {
  background-color: #ffb3b3;
}

.email-not-active {
  background-color: #ccffcc;
}

.psmall {
  font-size: 12px;
}

.modal-picture {
  /* width: 20%; */
}

.pet_picture {
  display: block;
  margin: auto;
}

.pet_picture_avatar {
  width: 200px;
  height: 150px;
}
