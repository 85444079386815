
.datetime-picker {
  width: 160px;
  text-align: center;
}
.react-datepicker-popper {
  z-index: 9999;
}

.ql-container {
  height: 150px !important;
}
