.DailyBook {
  padding: 7px;
}

.DailyBook form {
  padding-bottom: 15px;
}

.DailyBook form textarea {
  height: 300px;
  font-size: 24px;
}
.DailyBook .diaryBookBtn {
  display: inline-block;
  padding-left: 15px;
}
.DailyBook .detail-buttons .diaryBookBtn {
  padding-left: 0px;
}
.DailyBook .detail-buttons .diaryBookBtn .glyphicon {
  margin-right: 5px;
}
.DailyBook .btn-group > button {
  float: right;
  margin-left: 10px;
}
.DailyBook .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.DailyBook .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.DailyBook > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.DailyBook .pheader {
  padding-right: 15px;
}

.DailyBook .date-picker {
  display: block;
    text-align: center;
    width: 120px;
    height: 34px;
    padding: 6px 12px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.bookBtn {
  padding-left: 10px;
}

.printBtnGroup {
  margin-left: 10px;
}

@media (max-width: 767px)  {
  .printBtnGroup {
    display: none;
  }
}
