/* .Home .container {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
} */

.ui.inverted.segment {
  background: hsla(0,1%,1%,.76);
}

.app-logo-home {
  width: 310px !important;
  padding: 10px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  background: hsla(360, 100%, 100%, 0.5) !important;
}

.footer-3 {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}
