.ColSetup {
  text-align: left !important;
  margin-left: 15px !important;
  vertical-align: middle !important;
}

.RowSetup {
  text-align: left !important;
  border-bottom: 1px solid #eee;
  vertical-align: middle !important;
}

.LabelSetup {
  text-align: left !important;
}

#store_max_time, #parking_max_time {
  width: 150px;
}

.RestrictedSetup {
  color: red;
}

.Setup .headerBtn  {
  float: none;
}

.Setup .sectionBtn {
  margin-left: 15px;
  margin-bottom: 15px;
}
