.Resident .residents {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Resident .row [class^=col-]{
		text-align: center;
	}

  .Resident table thead tr th:nth-child(3),
	.Resident table thead tr th:nth-child(4),
  .Resident table thead tr th:nth-child(5),
  .Resident table thead tr th:nth-child(6),
  .Resident table thead tr th:nth-child(7),
  .Resident table thead tr th:nth-child(8),
  .Resident table tbody tr td:nth-child(3),
  .Resident table tbody tr td:nth-child(4),
  .Resident table tbody tr td:nth-child(5),
  .Resident table tbody tr td:nth-child(6),
  .Resident table tbody tr td:nth-child(7),
  .Resident table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Resident .row [class^=col-]{
		text-align: center;
	}
  .Resident table thead tr th:nth-child(7),
  .Resident table thead tr th:nth-child(8),
	.Resident table tbody tr td:nth-child(7),
  .Resident table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.email-active {
  background-color: #ffb3b3;
}

.email-not-active {
  background-color: #ccffcc;
}

.psmall {
  font-size: 12px;
}

.resident_picture_modal {
  display: block !important;
  margin: auto !important;
}

.resident_picture_avatar {
  width: 200px;
  height: 150px;
}
