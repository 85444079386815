.sign-out {
  text-align: right;
  background: #f5f5f5 !important;
}

.concierge {
  background: #f5f5f5 !important;
}

.logo_image_v {
  /* width: 50px !important;
  height: 50px !important; */
}

.unit_label {
   max-width: 160px;
}

.unit_label_selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
