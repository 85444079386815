.Report .reports {
  margin-top: 15px;
}

.Report .footer {
  text-align: center;
}
@media (max-width: 767px) {
	.Report .row [class^=col-]{
		text-align: center;
	}
  .Report table thead tr th:nth-child(3),
	.Report table thead tr th:nth-child(4),
  .Report table thead tr th:nth-child(5),
  .Report table thead tr th:nth-child(6),
  .Report table thead tr th:nth-child(7),
  .Report table thead tr th:nth-child(8),
	.Report table tbody tr td:nth-child(3),
  .Report table tbody tr td:nth-child(4),
  .Report table tbody tr td:nth-child(5),
  .Report table tbody tr td:nth-child(6),
  .Report table tbody tr td:nth-child(7),
  .Report table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Report .row [class^=col-]{
		text-align: center;
	}
  .Report table thead tr th:nth-child(7),
  .Report table thead tr th:nth-child(8),
	.Report table tbody tr td:nth-child(7),
  .Report table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.Report .date-picker {
  display: block;
  text-align: center;
  width: 132px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
}
