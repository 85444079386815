.condo-logo-validation {
  height: 75px;
  width: 75px;
  margin-right: 20px;
}

.header-title-validation {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  vertical-align: middle;
  line-height: 75px;
}

.page-header-validation {
  text-align: center;
  font-weight: bold;
  vertical-align: middle;

}
