body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
}
h1, h2, h3, h4, h5, h6 {
/*   font-family: "PT Serif", serif; */
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
.PublicChecklist h1, .PublicChecklist .soon,
.PublicForm h1, .PublicForm .soon,
.PublicTalk h1, .PublicTalk .soon,
.PublicContact h1 {
  text-align: center;
}
.PublicChecklist .soon .row, .PublicChecklist .soon .row > div,
.PublicForm .soon .row, .PublicForm .soon .row > div,
.PublicTalk .soon .row, .PublicTalk .soon .row > div {
  background-color: inherit;
}
.PublicForm .page-header span,
.PublicTalk .page-header span {
  color: red;
}
.PublicChecklist .soon .row p {
  text-align: left;
  padding-top: 10px;
}
.PublicForm .soon .row p,
.PublicTalk .soon .row p {
  text-align: left;
  padding-top: 44px;
}
.PublicChecklist .soon .row ul,
.PublicForm .soon .row ul,
.PublicTalk .soon .row ul {
  text-align: left;
  font-size: 21px;
  font-weight: 200;
}
.PublicChecklist .soon .digital-message,
.PublicForm .soon .digital-message,
.PublicTalk .soon .digital-message {
  color: blue;
}
