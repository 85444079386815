table {
  width: 100%;
  text-align: center;
}

tbody {
  text-align: center;
  border-bottom: 1px solid gainsboro
}

.delete {
  background-color: red;
}

.prompt-delete {
background-color: #c73834;
color: gainsboro;
}