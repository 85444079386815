.Resident {
  padding: 7px;
}

.Resident form {
  padding-bottom: 15px;
}

.Resident form textarea {
  height: 300px;
  font-size: 24px;
}
.Resident .residentBtn {
  display: inline-block;
  padding-left: 15px;
}
.Resident .detail-buttons .residentBtn {
  padding-left: 0px;
}
.Resident .detail-buttons .residentBtn .glyphicon {
  margin-right: 5px;
}
.Resident .btn-group > button {
  float: right;
  margin-left: 10px;
}
.Resident .actionsBtn > button {
  float: none;
  margin-left: 50px;
}
.Resident .headerBtn > button {
  float: none;
  margin-left: 50px;
}
.Resident .btnAction {
    margin-right: 5px;
    margin-left: 5px;
}
.Profile .btnChangePass {
	margin-right: 5px;
}
.Profile .btnUpdateProfile {
	margin-left: 5px;
}
.Resident > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.radio {
	margin-left: 15px;
}

.Resident .pheader {
  padding-right: 15px;
}
