.Booking .bookings {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.Booking .row [class^=col-]{
		text-align: center;
	}
  .Booking table thead tr th:nth-child(3),
	.Booking table thead tr th:nth-child(4),
  .Booking table thead tr th:nth-child(5),
  .Booking table thead tr th:nth-child(6),
  .Booking table thead tr th:nth-child(7),
  .Booking table thead tr th:nth-child(8),
	.Booking table tbody tr td:nth-child(3),
  .Booking table tbody tr td:nth-child(4),
  .Booking table tbody tr td:nth-child(5),
  .Booking table tbody tr td:nth-child(6),
  .Booking table tbody tr td:nth-child(7),
  .Booking table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.Booking .row [class^=col-]{
		text-align: center;
	}
  .Booking table thead tr th:nth-child(7),
  .Booking table thead tr th:nth-child(8),
	.Booking table tbody tr td:nth-child(7),
  .Booking table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.received {
  background-color: #ffb3b3;
}

.departed {
  background-color: #ccffcc;
}

.radio {
	margin-left: 15px;
}

.psmall-center {
  font-size: 12px;
  text-align: center;
}

.penalty {
  background-color: #ffb3b3;
}

.nopenalty {
  background-color: #ccffcc;
}

.Booking .slots {
  margin-left: 20px;
}

.Booking .date-picker {
  display: block;
  text-align: center;
  width: 132px;
  height: 34px;
  padding: 6px 12px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-left: 15px;
}

.Booking .b_pending {
  background-color: #ffb3b3;
}

.Booking .b_active {
  background-color: #ccffcc;
}
