.ListDelivery .deliveries {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.ListDelivery .row [class^=col-]{
		text-align: center;
	}
  .ListDelivery table thead tr th:nth-child(3),
	.ListDelivery table thead tr th:nth-child(4),
  .ListDelivery table thead tr th:nth-child(5),
  .ListDelivery table thead tr th:nth-child(6),
  .ListDelivery table thead tr th:nth-child(7),
  .ListDelivery table thead tr th:nth-child(8),
	.ListDelivery table tbody tr td:nth-child(3),
  .ListDelivery table tbody tr td:nth-child(4),
  .ListDelivery table tbody tr td:nth-child(5),
  .ListDelivery table tbody tr td:nth-child(6),
  .ListDelivery table tbody tr td:nth-child(7),
  .ListDelivery table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.ListDelivery .row [class^=col-]{
		text-align: center;
	}
  .ListDelivery table thead tr th:nth-child(7),
  .ListDelivery table thead tr th:nth-child(8),
	.ListDelivery table tbody tr td:nth-child(7),
  .ListDelivery table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.received {
  background-color: #ffb3b3;
}

.delivered {
  background-color: #ccffcc;
}

.no_residents {
  padding-top: 7px;
}

.resident_select_list {
  z-index: 9999;
}

.sign-modal-dialog {
    padding: 0px !important;
}

.sign-modal-dialog .modal-dialog {
    z-index: 9999;
    width: 100VW !important;
    display: block !important;
    margin: auto !important;
    padding: 0px !important;
}

.sign-modal-dialog .modal-body {
    padding: 0px !important;
}
