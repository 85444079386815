.root, .App, .row, .row > div {
  padding: 0;
  margin: 0;
  /* background-color: white; */
}
.App {
  width: 100%;
}
.App .internetAccess {
  position: fixed;
  z-index: 999;
  width: 100%;
  text-align: center;
}
.App .page-header h1 {
  margin-left: 15px;
}

.App footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 4;
}
.App footer .nav-footer {
  padding: 0 20px;
  font-family: Helvetica Neue,Roboto,Arial,Droid Sans,sans-serif;
  text-align: left;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  background-color: #f5f5f5;
  margin-top: 1px;
  position: relative;
}
.App footer .nav-footer > a {
  min-width: 50px;
  text-align: center;
  text-decoration: none;
  float: right;
  font-weight: regular;
  color: #111;
  font-size: 12px;
  padding: 0 1em;
}
.App footer .nav-footer .copyright {
  margin: 0 auto;
  padding: 0 2em 0 2em;
  font-weight: regular;
  color: #111;
  font-size: 12px;
  padding: 0 1em;
}
.App .navbar {
  background: inherit;
  border: 0;
  box-shadow: none;
}
.App .navbar .container {
  width: 100%;
}
.App .navbar .container, .App .navbar .navbar-collapse {
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .App footer .nav-footer {
    height: 30px;
  }
  .App .navbar .container {
    padding: 0;
  }
  .App .navbar .navbar-collapse {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .App .navbar .navbar-collapse .menu {
    width: 100%;
  }

  .page-header {
    margin: 20px;
  }

  .help {
    display: none;
  }

  .logo-footer {
    display: none;
  }
}

.page-header {
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
}

.App .btn-primary {
  /* background-image: linear-gradient(-179deg,#5690FF 0,#387DFF 97%); */
  background-repeat: repeat;
  font-weight: 600;
}
.App .btn-success {
  background-repeat: repeat;
  font-weight: 600;
}
.App .navbar-brand {
  font-weight: bold;
}
.userContent {
  padding: 7px;
  zoom: 1.3;
  margin-left: 5px;
}
.profile {
  padding-left: 10px;
}
.profile button {
  border-color: transparent;
}
.spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}
.App > .row > div > div.lander,
.App > .row > div.PublicContact {
  padding-top: 50px;
}
.App > .row > div.Login {
  padding-top: 80px;
}
.navbar {
  margin-bottom: 5px;
}
.App .navbar-default .navbar-nav>li>a.btn-success,
.App .navbar-default .navbar-nav>li>a.btn-primary {
  color: white;
}
.image-responsive {
  width: 100%;
  height: auto;
}
.help {
	float: right;
	cursor: pointer;
  padding-left: 10px;
}

.pending_payment_advice {
  margin-left: 10px !important;
  cursor: pointer;
}

.version_log {
  cursor: pointer;
}

.marquee {
  width: 350px;
  height: 15px !important;
  line-height: 100%;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}
