.ListDeliverySummary .deliveries {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.ListDeliverySummary .row [class^=col-]{
		text-align: center;
	}
  .ListDeliverySummary table thead tr th:nth-child(3),
	.ListDeliverySummary table thead tr th:nth-child(4),
  .ListDeliverySummary table thead tr th:nth-child(5),
  .ListDeliverySummary table thead tr th:nth-child(6),
  .ListDeliverySummary table thead tr th:nth-child(7),
  .ListDeliverySummary table thead tr th:nth-child(8),
	.ListDeliverySummary table tbody tr td:nth-child(3),
  .ListDeliverySummary table tbody tr td:nth-child(4),
  .ListDeliverySummary table tbody tr td:nth-child(5),
  .ListDeliverySummary table tbody tr td:nth-child(6),
  .ListDeliverySummary table tbody tr td:nth-child(7),
  .ListDeliverySummary table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.ListDeliverySummary .row [class^=col-]{
		text-align: center;
	}
  .ListDeliverySummary table thead tr th:nth-child(7),
  .ListDeliverySummary table thead tr th:nth-child(8),
	.ListDeliverySummary table tbody tr td:nth-child(7),
  .ListDeliverySummary table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}

.received {
  background-color: #ffb3b3;
}

.delivered {
  background-color: #ccffcc;
}

.resident_select_list {
  z-index: 9999;
}

.sign-modal-dialog {
    padding: 0px !important;
}

.sign-modal-dialog .modal-dialog {
    z-index: 9999;
    width: 100VW !important;
    display: block !important;
    margin: auto !important;
    padding: 0px !important;
}

.sign-modal-dialog .modal-body {
    padding: 0px !important;
}

.delivery-exceeded-label {
  background-color: green;
  animation-name: color_change_delivery;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@-webkit-keyframes color_change_delivery {
	from { background-color: red; }
	to { background-color: gray; }
}
@-moz-keyframes color_change_delivery {
  from { background-color: red; }
	to { background-color: gray; }
}
@-ms-keyframes color_change_delivery {
  from { background-color: red; }
	to { background-color: gray; }
}
@-o-keyframes color_change_delivery {
  from { background-color: red; }
	to { background-color: gray; }
}
@keyframes color_change_delivery {
  from { background-color: red; }
	to { background-color: gray; }
}
