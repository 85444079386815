.Contact .row > div > div {
  padding: 10px;
}
.Contact textarea {
  height: 140px;
}
.modalMap {
  width: 634px;
  margin: 0 auto;
  padding-left: inherit !important;
}

.contact h2 {
  text-transform: uppercase;
  font-weight: bold;
}
.contact form {
  margin-top: 38px;
}
.contact button {
  background: #ffcc00;
  border-color: #ffcc00;
  color: #000;
}
.contact button:active, .contact button:hover, .contact button:default {
  background: #eebb00;
  border-color: #eebb00;
  color: #000;
}
.contact .glyphicon {
  color: #eebb00;
}

.align-center {
  text-align: center;
}

@media (max-width: 767px)  {
  .Contact .info {
    display: none;
  }
  .modal-dialog .modal-body iframe {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: inherit;
  }
}
