.Report {
  padding: 7px;
}

.Report form {
  padding-bottom: 15px;
}

.Report form textarea {
  height: 300px;
  font-size: 24px;
}

.Report > .alert {
  margin: 15px;
  margin-bottom: 0;
}

.Report .pheader {
  padding-right: 15px;
}
