.User .users {
  margin-top: 15px;
}
@media (max-width: 767px) {
	.User .row [class^=col-]{
		text-align: center;
	}
  .User table thead tr th:nth-child(3),
	.User table thead tr th:nth-child(4),
  .User table thead tr th:nth-child(5),
  .User table thead tr th:nth-child(6),
  .User table thead tr th:nth-child(7),
  .User table thead tr th:nth-child(8),
	.User table tbody tr td:nth-child(3),
  .User table tbody tr td:nth-child(4),
  .User table tbody tr td:nth-child(5),
  .User table tbody tr td:nth-child(6),
  .User table tbody tr td:nth-child(7),
  .User table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
@media (max-width: 1024px) {
	.User .row [class^=col-]{
		text-align: center;
	}
  .User table thead tr th:nth-child(7),
  .User table thead tr th:nth-child(8),
	.User table tbody tr td:nth-child(7),
  .User table tbody tr td:nth-child(8)
	 {
		width: 0;
	}
}
