.resident_picture_previous {
  width: 100px;
  height: 80px;
  padding: 5px;
  border:1px solid lightgray;
}

.resident_picture_previous_text {
  padding-top: 10px;
}

.resident_cam_modal {
  z-index: -9999;
  visibility: hidden;
}
